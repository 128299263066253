import React, {useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import SideBar from "./MenuBar/SideBar";
import Navbar from "./Navbar";
import MainContent from "./content/mainContent";
import StudentContent from "./content/studentContent";
import Admission from "./content/admission";
import TeacherList from "./content/teacher-list";
import Subjects from "../Academics/Subjects";
import AcademicClass from "../Academics/AcademicClass";
import Department from "../Academics/Department";
import ClassRoom from "../Academics/ClassRoom";
import AuthContext from "../../context/student/AuthContext";
import Routine from "../Academics/Routine";
import FeeManager from "../Academics/FeeManager";
import TeacherRegistration from "./TeacherRegistration";
import AdminRegisteration from "./content/Admin Registration";
import FeeStructure from "./content/feeStructure";
import FeeCollection from "./content/feeCollection";
import RegisterStructure from "./content/RegisterFeeStructure";

const Home = () => {
  const { name, user } = useContext(AuthContext);
  

  return (
    <>
      <div className="column">
        <Navbar name={name} type={user} />
        <div className="row">
          <SideBar>
            <Routes>
              <Route element={<MainContent/>}></Route>
              <Route path="/home" element={<MainContent />} />
              <Route path="/Dashboard" element={<MainContent />} />
              <Route path="/users/student" element={<StudentContent />} />
              <Route path="/registration/student" element={<Admission />} />
              <Route path="/registration/teacher" element={<TeacherRegistration />} />
              <Route path="/users/teacher" element={<TeacherList />} />
              <Route path="/registration/admin" element={<AdminRegisteration />} />

              <Route path="/Alumni" element={<>Coming soon</>} />
              <Route path="/Academics/subject" element={<Subjects />} />
              <Route path="/Academics/class" element={<AcademicClass />} />
              <Route path="/Academics/department" element={<Department />} />
              <Route path="/Academics/class-room" element={<ClassRoom />} />
              <Route path="/Academics/Routine" element={<Routine />} />
              
              <Route path="/Accounting/fee-structure" element={<FeeStructure/>}/>
              <Route path="/Accounting/fee-structure/create" element={<RegisterStructure/>}/>
              <Route path="/Accounting/fee-collection" element={<FeeCollection/>}/>
              <Route path="/Accounting/invoice" element={<FeeManager/>}/>

              <Route path="/Live-Class" element={<>Coming soon</>} />
              <Route path="/Examination" element={<>Coming soon</>} />
              <Route path="/Accounting" element={<>Coming soon</>} />
              <Route path="/Back-Office" element={<>Coming soon</>} />
              <Route path="*" element={ <div style={{ display: 'flex', justifyContent: 'center' }}> <img height={"400px"} src="https://www.shutterstock.com/image-vector/coming-soon-page-concept-man-600nw-1483421201.jpg"/> </div> } />
            </Routes>
          </SideBar>
        </div>
      </div>
     
    </>
    
  );
};

export default Home;
