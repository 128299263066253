import React, { useState, useContext } from "react";
import "./style.css";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import StudentProfile from "../content/studentProfile";
import AuthContext from "../../../context/student/AuthContext";
import TeacherProfile from "../content/teacherProfile";
import PersonImage from './person.png'

const Navbar = (props) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const { userName } = useContext(AuthContext);

  const handleItemClick = () => {
    navigate("/logout");
  };

  const profileOnClick = () => {
    setShow(true);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg 
      navbar-dark my-nav">
        <a
          className="myLogo"
          href="/"
          style={{
            fontSize: "1.5rem",
            fontFamily: "monospace",
            fontWeight: "bold",
          }}
        >
          Mother's Goose
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <nav className="navbar navbar-dark ">
                <a
                  className="navbar-brand"
                  href="#"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      variant="Secondary"
                      style={{ background: "none", border: "none", padding: 0 }} // Optional: style the toggle button to remove background and border
                    >
                      <img
                        src={PersonImage}
                        height="50"
                        alt=""
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onSelect={profileOnClick}
                        style={{ fontSize: "20px", fontWeight: "400" }}
                      >
                        Profile <ImProfile size={25} />
                      </Dropdown.Item>

                      <Dropdown.Item
                        onSelect={handleItemClick}
                        style={{ fontSize: "20px", fontWeight: "400" }}
                      >
                        Logout <RiLogoutBoxRLine size={25} />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <div  style={{ display: "flex", flexDirection: "column" }}>
                    {" "}
                    {/* Added a div wrapper for horizontal alignment */}
                    <span className="account-user-name"
                      style={{ fontSize: "1.25rem", fontFamily: "monospace" }}
                    >
                      {props.name}
                    </span>{" "}
                    {/* Adjusted font size */}
                    <span className="account-position" style={{ fontSize: "1rem" }}>
                      {props.type}
                    </span>{" "}
                    {/* Adjusted font size */}
                  </div>
                </a>
              </nav>
            </li>
          </ul>
        </div>
      </nav>
      {props.type === "Student" && (
        <StudentProfile
          student={userName}
          show={show}
          handleClose={handleClose}
        />
      )}

      {props.type === "Teacher" && (
        <TeacherProfile
          teacher={userName}
          show={show}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

Navbar.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

Navbar.defaultProps = {
  name: "XYZ",
  type: "ABC",
};

export default Navbar;
