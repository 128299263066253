import React from 'react'
import "./style.css"

const AboutUs = () => {
  return (
    <>
       <div id="about" className="about">
        <div
          className="responsiveContainerBlock bigContainer"
        >
          <div
            className="responsiveContainerBlock Container" 
          >
            <p className="textBlk heading">About Us</p>
            <p className="textBlk subHeading">
              At Mother's Goose, we are dedicated to fostering a nurturing and
              inspiring environment where every child feels valued and
              encouraged to explore their potential. Our mission is to provide a
              safe and stimulating space where children can grow, learn, and
              thrive. We emphasize respect, creativity, integrity, and
              excellence in all our activities, aiming to instill a lifelong
              love of learning and strong moral principles in our students. By
              creating a supportive community, we ensure that each child can
              develop their unique abilities and build a strong foundation for
              their future.
            </p>
            <div className="socialIconsContainer">
              <a className="socialIcon">
                <img
                  alt=""
                  className="socialIconImageBlock"
                  src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/bb33.png"
                />
              </a>
              <a className="socialIcon">
                <img
                  alt=""
                  className="socialIconImageBlock"
                  src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/bb34.png"
                />
              </a>
              <a className="socialIcon">
                <img
                  alt=""
                  className="socialIconImageBlock"
                  src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/bb35.png"
                />
              </a>
              <a className="socialIcon">
                <img
                  alt=""
                  className="socialIconImageBlock"
                  src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/bb36.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs
