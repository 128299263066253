import React, { useContext, useEffect, useState } from 'react'
import "./style.css";
import InputComponent from '../content/admission/inputComponent';
import { RegisterUser } from '../../../apis/CRUD_operation/operation';
import { useNavigate } from "react-router-dom";
import AuthContext from '../../../context/student/AuthContext';
import { GetAll } from '../../../apis/Academic CRUD/AC_CRUD';
import { ToastContainer } from "react-toastify";
import NotifyMsg from '../../Academics/NotifyMsg';
import DashBoardCard from '../DashBoardCard/DashBoardCard';


const TeacherRegistration = () => {

  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [classList, setClassList] = useState([]);
  const [notificationMsg, setMsg] = useState(null); //Msg content
  const [typeOfMsg, setMsgType] = useState(); //Type of msg
  const [isRegistering, setIsRegistering] = useState(false);
  const [registrationProgress, setRegistrationProgress] = useState(0); 

  useEffect(() => {
    fetchData();
  }, []); // Make sure to include dependencies array to avoid infinite loop

  const fetchData = async () => {
    try {
      const responseData = await GetAll("academic", token);
      setClassList(responseData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [stringData, setStringData] = useState({
    userType: { value: "teacher"},
    firstName: { value: "", type: "text", size: 6 },
    lastName: { value: "", type: "text", size: 6 },
    email: { value: "", type: "email", size: 6 },
    dateOfBirth: { value: "", type: "date", size: 3 },
    gender: { value: "", type: "select", size: 3 },
    phone_num: { value: "", type: "number", size: 6 },
    emergency_contact: { value: "", type: "number", size: 6 },
    security_number: { value: "", type: "text", size: 6 },
    qualification: { value: "", type: "text", size: 6 },
    specialization: { value: "", type: "text", size: 6 },
    grade_level: { value: "", type: "text", size: 3 },
    teaching_experience: { value: "", type: "number", size: 3 },
    joining_date: { value: "", type: "date", size: 4 },
    subjects: { value: "", type: "text", size: 4 },
    position: { value: "", type: "text", size: 4 },
    address: { value: "", type: "textarea", size: 12 },
  });

  const formatLabel = (key) => {
    return key
      .replace(/_/g, ' ')
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStringData((prevStringData) => ({
      ...prevStringData,
      [name]: { ...prevStringData[name], value }, // Update the 'value' property within the corresponding object
    }));
  };


  const registerUserWithProgress = async (
    userType,
    postData,
    progressCallback
  ) => {
    return new Promise(async (resolve, reject) => {
      // Simulate slow API call with progress updates
      const totalTime = 3000; // Simulate slow API call for 3 seconds
      const interval = 100; // Update progress every 100ms
      let currentTime = 0;

      const progressInterval = setInterval(() => {
        currentTime += interval;
        const progress = Math.min((currentTime / totalTime) * 100, 100);
        progressCallback(progress);

        if (currentTime >= totalTime) {
          clearInterval(progressInterval);
          resolve("Teacher registered successfully");
        }
      }, interval);

      // Simulate registration API call
      try {
        const data = await RegisterUser(userType, postData);
        resolve(data);
      } catch (error) {
        clearInterval(progressInterval);
        reject(error);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = Object.values(stringData);

    const postData = values.reduce((acc, { value }, index) => {
      const key = Object.keys(stringData)[index];
      acc[key] = value.trim();
      return acc;
    }, {});
    e.preventDefault();
    const isEmpty = checkEmptyValues(postData);
    if (isEmpty) {
      setIsRegistering(true);
      const data = await registerUserWithProgress(
        "teacher",
        postData,
        setRegistrationProgress
      );
      if (data === "User already registered.") {
        setMsg("Teacher already registerd");
        setMsgType("error");
        setIsRegistering(false);
      } else {
        setMsg("Teacher register successfully");
        setMsgType("success");
        setIsRegistering(false);
        setTimeout(() => {
          navigate("/users/teacher");
        }, 3000);
      }
    } else {
      setMsg("Fill the fields");
      setMsgType("warning");
    }
  };
  function checkEmptyValues(teacherInfo) {
    for (const key in teacherInfo) {
      if (!teacherInfo[key]) {
        return false;
      }
    }
    return true;
  }

  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#F4F5F9", maxWidth: "1424px" }}
      >
        <DashBoardCard title="Add a teacher" visibility="hidden" />

        {isRegistering && (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: "999",
              backdropFilter: "blur(5px)", // Apply blur effect to background
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "40%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
              }}
            >
              <div style={{ textAlign: "center" }}>
                <progress
                  value={registrationProgress}
                  max="100"
                  style={{ width: "800px", height: "20px" }} // Set width and height for the progress bar
                />
                <p style={{ fontSize: "1.2rem" }}>
                  Registering teacher. Please wait...
                </p>
              </div>
            </div>
          </div>
        )}

        <section id="form" onSubmit={handleSubmit}>
          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-md-12 text-center">
                <h3
                  className="text-uppercase"
                  style={{ marginBottom: "1.5rem" }}
                >
                  Registration
                </h3>

                <div className="row">
                  <div className="col-md-12">
                    <form>
                      <div className="form-row">
                        {Object.entries(stringData)
                          .slice(1, 11)
                          .map(([menuItem, component]) => (
                            <InputComponent
                              label={formatLabel(menuItem)}
                              // key={menuItem}
                              lable={formatLabel(menuItem)}
                              size={component.size}
                              type={component.type}
                              name={menuItem}
                              value={component.value}
                              onChange={(e) => handleInputChange(e)}
                              placeholder={`Enter your ${formatLabel(menuItem)}`}
                            />
                          ))}

                        <div className="form-group col-md-3">
                          <h5 className="text-left">Grade level</h5>
                          <select
                            className="form-control"
                            id="inputDado"
                            name="grade_level"
                            value={stringData.grade_level.value}
                            onChange={(e) => handleInputChange(e)}
                            required
                          >
                            <option selected>Select class...</option>
                            {classList.map((result) => (
                              <option value={result.className}>{result.className}</option>
                            ))}
                          </select>
                        </div>

                        {Object.entries(stringData)
                          .slice(12)
                          .map(([menuItem, component]) => (
                            <InputComponent
                              label={formatLabel(menuItem)}
                              // key={menuItem}
                              lable={formatLabel(menuItem)}
                              size={component.size}
                              type={component.type}
                              name={menuItem}
                              value={component.value}
                              onChange={(e) => handleInputChange(e)}
                              placeholder={`Enter your ${formatLabel(menuItem)}`}
                            />
                          ))}

                      </div>

                      <button type="submit" className="btn btn-lg btn-secondary">
                       Register
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <NotifyMsg msg={notificationMsg} type={typeOfMsg} />
      <ToastContainer />
    </>
  );
};

export default TeacherRegistration
