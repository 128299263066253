import React from "react";
import './style.css';
import PropTypes from "prop-types";

const InputComponent = ({ label, type, name, value, placeholder, onChange, size }) => {
  return (
    <div className={`form-group col-md-${size}`}>
      <h5 className="text-left">{label}</h5>
      {name === "gender" ? (
        <select
          className="form-control"
          id="inputDado"
          name={name}
          value={value}
          onChange={onChange}
          required
        >
          <option value="">Select Gender...</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </select>
      ) : (
        <input
          id="inputDado"
          type={type}
          name={name}
          className="form-control"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required
          minLength={2}
        />
      )}
    </div>
  );
};

InputComponent.defaultProps = {
  size: 6,
};

InputComponent.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number
};

export default InputComponent;
