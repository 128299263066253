import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './components/Login';
import Home from './components/Dashboard/Home';
import Logout from './components/Logout';
import { AuthProvider } from './context/student/AuthContext';
import HomePage from './components/Dashboard/content/HomePage';
import Registration_query from './components/Dashboard/content/Registration_query';

function App() {
  return (
    
    
    <AuthProvider>
      <Router>

      
        <Routes>

        <Route path="/" element={<HomePage/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/registrationQuery" element={<Registration_query/>}/>
        <Route path='/logout' element={<Logout/>}/>

        <Route path="/*" element={<Home />} />

      {/* Route for the sidebar */}
      
        </Routes>

      
      </Router>
      
    </AuthProvider>
    
   
  );
}

export default App;
