const BASE_URL = "http://34.29.32.114:9902/tva/school-management/api/v1";
const CreateEntity = async (postData, name, token) => {
  console.log("POst Data is ", postData);
  try {
    const response = await fetch(`${BASE_URL}/${name}/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(postData),
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      return data;
    }
  } catch (error) {
    throw new Error("Create entity failed", error);
  }
};

const GetAll = async (name, token) => {
  try {
    const response = await fetch(`${BASE_URL}/${name}/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const responseData = await response.json();

    if (responseData.status === 200) {
      return responseData;
    } else {
      throw new Error("Request failed with status " + responseData.status);
    }
  } catch (error) {
    throw new Error("GET entity failed", error);
  }
};

const Delete = async (id, name, token) => {
  try {
    const response = await fetch(`${BASE_URL}/${name}/delete/${id}`, {
      method: "Delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw new Error("Delete entity failed", error);
  }
};

const UpdateEnitity = async (id, postData, name, token) => {
  try {
    const response = await fetch(`${BASE_URL}/${name}/update/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(postData),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw new Error("Update entity failed", error);
  }
};

export { CreateEntity, GetAll, Delete, UpdateEnitity };
