import React, { useState } from "react";
import "./style.css";
import UserList from "../../UserList";
import DashBoardCard from "../../DashBoardCard/DashBoardCard";
import DropDownComponent from "../../../Academics/DropDown";

const StudentContent = () => {
  const [Isvisibility, setVisibility] = useState(false);
  const [filterData, setFilter] = useState(null);
  

  const handleOnClick = (className, sectionName) => {
    
      setVisibility(true);
      setFilter({
        "grade": className,
        "section": sectionName
      });
  };


  return (
    <>
      <div className="container "  style={{ backgroundColor: "#F4F5F9", maxWidth: "100%" }}>
      <DashBoardCard
          title="Student"
          buttonValue="Add a student"
          visibility="visible"
          itemClick=""
        />
        <div className="card bg-white shadow rounded-lg overflow-hidden ">
          <DropDownComponent
          handleOnClick={handleOnClick}
          isSection={true}
          />
          {Isvisibility && <UserList filter={filterData} handleOnClick={handleOnClick}/>}
        </div>
      </div>
    </>
  );
};

export default StudentContent;
