import React, { useState } from "react";
import "./style.css";
import AboutUs from "../HomePage/aboutPage";
import MyFooter from "../HomePage/footerComponent";
import HomeNav from "../HomePage/homeNav";
import { admissionQuery } from "../../../../apis/Login/AuthService";
import NotifyMsg from "../../../Academics/NotifyMsg";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ThankYouPage from "../thankYouPage";


const Registration_query = () => {
  const navigate = useNavigate();
  const [notificationMsg, setMsg] = useState(null); //Msg content
  const [typeOfMsg, setMsgType] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false); // New state for form submission status
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    class: "",
    query: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = await admissionQuery(formData);
    if (data.status === 200) {
      setMsg("Query submitted successfully");
      setMsgType("success");
      setFormSubmitted(true); // Set form submitted status to true
    } else {
      setMsg("Some error occurred, Please try again later");
      setMsgType("error");
    }
    
  };

  return (
    <>
      <div className="container-fluide">
        <div className="row">
          <HomeNav />
        </div>
       
        <div className="row">
          {formSubmitted ? (
            <ThankYouPage /> // Render Thank You component if form is submitted
          ) : (
            <div className="admission-container">
              <div className="admission-left">
                <h1>Admission</h1>
                <div className="breadcrumb">
                  <span style={{ color: "black" }}>Home </span> &gt;{" "}
                  <span> Admission</span>
                </div>
                <p>
                  At Mother's Goose Preschool, we nurture young minds with love
                  and care, providing a safe and stimulating environment for your
                  child's early education. Our dedicated team of experienced
                  educators is committed to fostering creativity, curiosity, and a
                  lifelong love of learning in every child. We maintain open
                  communication and involve families in their child's educational
                  journey through regular updates, parent-teacher meetings, and
                  community events. Together, we create a supportive and enriching
                  environment where children can thrive. Join us at Mother's Goose
                  Preschool and let us be a part of your child's exciting journey
                  of discovery and growth.
                </p>
              </div>
              <div className="admission-right">
                <h2>Admission Enquiry AY 2024-25</h2>
                <form onSubmit={handleSubmit}>
                  {" "}
                  <input
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    value={formData.fullName}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Id"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="mobileNumber"
                    placeholder="Mobile Number"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    required
                  />
                  <select
                    name="class"
                    value={formData.class}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Class</option>
                    <option value="Play Ground">Play Group</option>
                    <option value="Nursery">Nursery</option>
                    <option value="LKG">LKG</option>
                    <option value="UKG">UKG</option>
                  </select>
                  <textarea
                    name="query"
                    placeholder="Your Query (Optional)"
                    rows="4"
                    value={formData.query}
                    onChange={handleChange}
                  ></textarea>
                  <button type="submit">Submit</button>
                </form>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <AboutUs />
        </div>
        <div className="row">
          <MyFooter />
        </div>
      </div>
      <NotifyMsg msg={notificationMsg} type={typeOfMsg} />
      <ToastContainer />
    </>
  );
};

export default Registration_query;
