import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import './style.css'
import { CreateEntity } from '../../../apis/Academic CRUD/AC_CRUD';
import AuthContext from '../../../context/student/AuthContext';

const CreateAcademics = ({ show, onHide, title, details, inputConfigs }) => {

    const { token } = useContext(AuthContext);

    const [isButtonDisabled, setIsSubmitDisabled] = useState(false);

    useEffect(() => {
        const hasEmptyField = inputConfigs.some(config => config.value.trim() === '');
        setIsSubmitDisabled(hasEmptyField);
    }, [inputConfigs]);


    const onSubmit = async () => {
        setIsSubmitDisabled(true);
        await CreateEntity(details, title.charAt(0).toLowerCase() + title.slice(1), token);
        onHide();
    }

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add a {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {inputConfigs.map((config, index) => (
                        <div key={index}>
                            <label htmlFor={config.id}>{config.label}:</label>
                            <input
                                className="form-control"
                                name={config.name}
                                type={config.type}
                                id={config.id}
                                value={config.value}
                                onChange={config.onChange}
                            />
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <div className="form-group mt-2 col-md-12">
                        <button className="btn btn-block btn-primary" disabled={isButtonDisabled}
                            type="submit" onClick={onSubmit}>
                            Add {title}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateAcademics
