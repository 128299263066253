import React, { useContext, useEffect, useState } from 'react'
import { GetAll } from '../../../apis/Academic CRUD/AC_CRUD';
import AuthContext from '../../../context/student/AuthContext';
import { FaSearch } from "react-icons/fa";


const DropDownComponent = ({ isSection, handleOnClick }) => {

  const [data, setData] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const { token } = useContext(AuthContext);
  const [class_name, setClassName] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const responseData = await GetAll("academic", token);
      setData(responseData.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");

  const handleClassChange = (event) => {
    event.preventDefault();
    const selectedClassIndex = event.target.value;
    setSelectedClass(selectedClassIndex);
      const sections = data[selectedClassIndex].sections.map(section => section.sectionName);
      setClassName(data[selectedClassIndex].className);

      setSelectedSection("");
      setSectionList(sections);
    
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  return (
    <>
      <div className="card-body p-4 text-center ">
        <div className="d-flex flex-wrap gap-4 items-end">
          <div className="flex-grow-1">
            <label className="form-label" htmlFor="libraryClassDropdown">
              Class
            </label>
            <select
              className="form-select"
              id="libraryClassDropdown"
              aria-label="Class select"
              onChange={handleClassChange}
              value={selectedClass}
            >
              <option selected value>Select class...</option>
              {
                data.map((result, index) => (
                  <option value={index}>{result.className}</option>
                ))
              }
            </select>
          </div>

          {
            isSection ? <div className="flex-grow-1">
              <label className="form-label" htmlFor="librarySectionDropdown">
                Section
              </label>
              <select
                className="form-select"
                id="librarySectionDropdown"
                aria-label="Section select"
                onChange={handleSectionChange}
                disabled={!selectedClass}
                value={selectedSection}
              >
                <option selected value>Select section...</option>
                {
                  sectionList.map((result, index) => (
                    <option value={result}>{result}</option>
                  ))
                }

              </select>
            </div> : null
          }


          <div className="flex-grow-1">
            <button
              type="button"
              style={{marginTop:"1.7rem"}}
              className="btn btn-secondary"
              onClick={() => handleOnClick(class_name, selectedSection)}
              disabled={isSection ? !selectedSection : !selectedClass}
            >
              <FaSearch />

            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default DropDownComponent
