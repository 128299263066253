import React, { useEffect, useState, useContext } from "react";
import { LiaFileExportSolid } from "react-icons/lia";
import AuthContext from "../../../context/student/AuthContext";
import { fetchListData } from "../../../apis/CRUD_operation/operation";
import { useNavigate } from "react-router-dom";
const Cards = ({ title }) => {
  const { token } = useContext(AuthContext);

  const [totalUser, setUser] = useState(null);
  const navigate = useNavigate();
  const requiredData = {
    userType: title,
    filter: {},
    projection: {
      username: 1,
    },
  };

  const fetchData = async () => {
    fetchListData(token, requiredData).then((newData) => {
      setUser(newData.length);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const NavigatePage = () => {
    navigate(`/users/${title}`)
  };

  return (
    <>
      <div className="card widget-flat" id="student">
        <div className="card-body">
          <div className="float-right"></div>
          <h5
            className="text-muted font-weight-normal mt-0 text-capitalize"
            title="Number of Student"
          >
            {" "}
            {title}
            <LiaFileExportSolid
              onClick={NavigatePage}
              style={{ cursor: "pointer" }}
            />
          </h5>
          <h3 className="mt-3 mb-3">{totalUser} </h3>
          <p className="mb-0 text-muted">
            <span className="text-nowrap">Total number of {title}s</span>
          </p>
        </div>
      </div>
    </>
  );
};

Cards.defaultProps = {
  title: "student"
} 

export default Cards;
