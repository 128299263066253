import React, { useEffect, useState, useContext } from 'react'
import { FaUserCircle, FaPen } from 'react-icons/fa';
import AuthContext from '../../../context/student/AuthContext';
import demoUser from './user.png'
import { MdDelete } from "react-icons/md";
import DeleteConfirmation from '../content/deleteModal';
import StudentProfile from '../content/studentProfile';
import Student_update from '../content/Student_update';
import { fetchListData } from '../../../apis/CRUD_operation/operation';
import EmptyImage from '../../../Images/Empty.png';
import SearchBox from '../SearchBox';
import CustomLoadingBar from '../../Academics/LoadingBar/Index';
import Pagination from '../content/Pagination';

const UserList = ({ filter }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectName, setSelectName] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [studentList, SetStudentList] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentPerPage] = useState(5);


  const { user, token } = useContext(AuthContext);

  const fetchData = async () => {
    fetchListData(token, requiredData).then((newData) => {
      SetStudentList(newData);
      setDataList(newData);
    });
    setIsLoading(false);

  }

  useEffect(() => {
    fetchData();
  }, [filter]);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    fetchData();
    setShow(false);
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const requiredData = {
    "userType": "student",
    "filter": filter,
    "projection": {
      "firstName": 1,
      "lastName": 1,
      "email": 1,
      "grade": 1,
      "section": 1,
      "username": 1,
    }
  };

  const handleItemClick = async (action, index) => {
    if (action === "Profile") {
      setShow(true);
      setSelectedItem(currentStudents[index]);
      setSelectName("Profile");
    } else if (action === "Delete") {
      setModalShow(true);
      setSelectedItem(currentStudents[index]);
      setSelectName("Delete");
    } else if (action === "Update") {
      setShow(true);
      setSelectedItem(currentStudents[index]);
      setSelectName("Update");
    } else {
      setSelectedItem(null);
    }
  };

  // For search list 
  const setResults = (searchStudent) => {

    if (searchStudent === false)
      setDataList(studentList)
    else
      setDataList(searchStudent)
  }

  // For Pagination 
  // Get Current students 
  const indexOfLastStudent = currentPage * studentPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentPerPage;
  const currentStudents = dataList.slice(indexOfFirstStudent, indexOfLastStudent);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <>
      <CustomLoadingBar isLoading={isLoading} />

      {!isLoading ? (
        <>

          {currentStudents.length !== 0 ? (
            <> <SearchBox listStudent={studentList} setResults={setResults} />
              <table
                id="basic-datatable"
                className="table table-striped dt-responsive nowrap dataTable no-footer dtr-inline"
                width="90%"
                role="grid"
                aria-describedby="basic-datatable_info"
                style={{ position: "relative", marginTop: "20px" }}
              >
                <thead>
                  <tr role="row">
                    <th className="sorting" tabIndex="0" aria-controls="basic-datatable" rowSpan="1" colSpan="1" style={{ width: "73.8px", backgroundColor: "#313a46", color: "#ababab" }} aria-label="Photo: activate to sort column ascending">Photo</th>
                    <th className="sorting sorting_asc" tabIndex="0" aria-controls="basic-datatable" rowSpan="1" colSpan="1" style={{ width: "158.8px", backgroundColor: "#313a46", color: "#ababab" }} aria-sort="ascending" aria-label="Code: activate to sort column descending">Email</th>
                    <th className="sorting" tabIndex="0" aria-controls="basic-datatable" rowSpan="1" colSpan="1" style={{ width: "199.8px", backgroundColor: "#313a46", color: "#ababab" }} aria-label="Name: activate to sort column ascending">Name</th>
                    <th className="sorting" tabIndex="0" aria-controls="basic-datatable" rowSpan="1" colSpan="1" style={{ width: "91.8px", backgroundColor: "#313a46", color: "#ababab" }} aria-label="Options: activate to sort column ascending">Options</th>
                  </tr>
                </thead>
                <tbody>
                  {currentStudents.map((student, index) => (
                    <tr key={index} className="odd">
                      <td>
                        <img className="rounded-circle" width="50" height="50" src={demoUser} alt="User" />
                      </td>
                      <td className="dtr-control sorting_1" tabIndex="0">
                        <div style={{ position: 'absolute', height: '1px', width: '0px', overflow: 'hidden' }}>
                          <input type="text" tabIndex="0" />
                        </div>
                        {student.email}
                      </td>
                      <td>{capitalizeFirstLetter(student.firstName)} {" "} {capitalizeFirstLetter(student.lastName)}</td>
                      <td>
                        <FaUserCircle
                          size={28}
                          style={{ marginRight: '20px', cursor: 'pointer' }}
                          onClick={() => handleItemClick("Profile", index)}
                        />

                        {
                          user !== "Student" && <>

                            <MdDelete
                              size={30}
                              style={{ cursor: 'pointer', marginRight: '20px' }}
                              onClick={() => handleItemClick("Delete", index)}
                            />
                            <FaPen
                              size={24}
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleItemClick("Update", index)}
                            /> </>

                        }

                      </td>
                    </tr>
                  ))}
                </tbody>
                <Pagination
                  postsPerPage={studentPerPage}
                  totalPosts={studentList.length}
                  paginate={paginate}
                />
              </table>
            </>
          ) : (
            <div className="text-center">
              <img src={EmptyImage} height={"20px"} style={{ marginTop: "10px" }} className="img-fluid" alt="..." />
            </div>
          )}
        </>
      ) : (
        <div>Loading...</div>
      )}




      {
        selectName === "Profile" && (

          <StudentProfile
            student={selectedItem.username}
            show={show}
            handleClose={handleClose} />
        )
      }

      {
        selectName === "Delete" && (
          <DeleteConfirmation 
          userName={selectedItem.username} accountType={"student"} 
          
          show={modalShow} onHide={() => {
            setModalShow(false);
            fetchData();
          }} />
        )
      }

      {
        selectName === "Update" && (
          <Student_update student={selectedItem.username} 
          show={show} 
          onHide={handleClose} />
        )
      }

    </>
  )
}

export default UserList
