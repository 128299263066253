import { NavLink } from "react-router-dom";
import { FaBars, FaHome, FaGraduationCap, FaClipboardList, FaUser } from "react-icons/fa";


import { BiCog } from "react-icons/bi";
import { MdAccountBalance , MdLiveTv} from "react-icons/md";
import "./style.css"
import { useState } from "react";
import { SiMicrosoftacademic } from "react-icons/si";
import { FaUserPlus } from "react-icons/fa6";

import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
const routes = [
  {
    path: "/home",
    name: "Dashboard",
    icon: <FaHome />,
    
  },
  {
    path: "/users",
    name: "Users",
    icon: <FaUser />,
    subRoutes: [
      {
        path: "/users/student",
        name: "Student",
      },
      
      {
        path: "/users/teacher",
        name: "Teacher",
      },
      {
        path: "/users/teacher-permission",
        name: "Teacher Permission",
      },
      {
        path: "/users/parent",
        name: "Parent",
      },
      {
        path: "/users/accountant",
        name: "Accountant",
      },
      {
        path: "/users/librarian",
        name: "Librarian",
      },
    ],
  },
  {
    path: "/registration",
    name: "Registration",
    icon: <FaUserPlus />,
    exact: true,
    subRoutes: [
      {
        path: "/registration/student",
        name: "Student",
      },
      {
        path: "/registration/teacher",
        name: "Teacher",
      },
      {
        path: "/registration/Admin",
        name: "Admin",
      },
      
    ],
  },
  
  {
    path: "/Alumni",
    name: "Alumni",
    icon: <FaGraduationCap />,
    subRoutes: [
      {
        path: "/Alumni/Manage",
        name: "Manage Alumni",
      },
      {
        path: "/Alumni/Fallary",
        name: "Gallery",
      },
      {
        path: "/Alumni/Events",
        name: "Events",
      },
    ],
  },

  {
    path: "/Academics",
    name: "Academics",
    icon: <SiMicrosoftacademic />,
    exact: true,
    subRoutes: [
      // {
      //   path: "/Academics/Daily",
      //   name: "Daily Attendance ",
        
      // },
      {
        path: "/Academics/Routine",
        name: "Class Routine",
      },
      {
        path: "/Academics/subject",
        name: "Subject",
      },
      // {
      //   path: "/Academics/syllabus",
      //   name: "Syllabus",
      // },
      {
        path: "/Academics/class",
        name: "Class",
      },{
        path: "/Academics/class-room",
        name: "Class room",
      },
      {
        path: "/Academics/department",
        name: "Department",
      },
    ],
  },
  {
    path: "/Live-Class",
    name: "Live class",
    icon: <MdLiveTv />,
    exact: true,
    subRoutes: [
      {
        path: "/Live-Class/setting",
        name: "Live class setting",
      },
      
    ],
  },
  {
    path: "/Examination",
    name: "Examination",
    icon: <FaClipboardList />,
    exact: true,
    subRoutes: [
      {
        path: "/Examination/Marks",
        name: "Marks ",
      },
     
      {
        path: "/Examination/Grades",
        name: "Grades",
      },
      {
        path: "/Examination/Promotion",
        name: "Promotion",
      }
    ],
  },
  {
    path: "/Accounting",
    name: "Accounting",
    icon: <MdAccountBalance />,
    exact: true,
    subRoutes: [
      {
        path: "/Accounting/fee-structure",
        name: "Fee Structure",
      },
      {
        path: "/Accounting/fee-collection",
        name: "Fee Collection",
      },
      {
        path: "/Accounting/invoice",
        name: "Invoice",
      },
      
    ],
  },
  
  {
    path: "/setting",
    name: "Setting",
    icon: <BiCog />,
  }
];

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "200px" : "45px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  Navigation
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>
          <section style={{padding:"0px"}} className="routes">
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                  key={index}
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </motion.div>

        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
