import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from "prop-types";

import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';

const NotifyMsg = (props) => {
    useEffect(() => {
      console.log("Notify called...")
        const notify = () => {
            toast[props.type](props.msg, {
                position: "top-right",
                autoClose: props.type === "danger" ? 5000 : 3000, 
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce
            });
        };

        notify(); 
    }, [props.msg]); 

    return null; 
};

NotifyMsg.defaultProps = {
    type: "success",
  };
  

export default NotifyMsg;
