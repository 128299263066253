const loginUser = async (userData) => {
  try {
    const response = await fetch(
      `https://13.53.163.70:9901/tva/user-authorization/api/v1/user/loginUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      }
    );
    const data = await response.json();

    if (data.status === 200 && data.message === "Login Success") {
      return { data_set: data.data, error: null };
    } else if (data.status === 200 && data.message === "Login Failed") {
      return { data_set: null, error: "Invalid Password" };
    } else {
      return { data_set: null, error: "Check for Username or Account" };
    }
  } catch (error) {
    return {
      data_set: null,
      error: "An error occurred. Please try again later.",
    };
  }
};

const admissionQuery = async (formData) => {
  try {
    const response = await fetch(
      `https://13.53.163.70:9901/tva/user-authorization/api/v1/user/registerEnquiry`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Register  failed");
    }
  } catch (error) {
    return {
      data_set: null,
      error: "An error occurred. Please try again later.",
    };
  }
};

export { loginUser, admissionQuery };
