import React, { useContext, useEffect, useState } from "react";
import InputComponent from "./inputComponent";
import { RegisterUser } from "../../../../apis/CRUD_operation/operation";
import DashBoardCard from "../../DashBoardCard/DashBoardCard";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/student/AuthContext";
import { GetAll } from "../../../../apis/Academic CRUD/AC_CRUD";
import { ToastContainer } from "react-toastify";
import NotifyMsg from "../../../Academics/NotifyMsg";

const Admission = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [notificationMsg, setMsg] = useState(null); //Msg content
  const [typeOfMsg, setMsgType] = useState(); //Type of msg
  const [isRegistering, setIsRegistering] = useState(false);
  const [registrationProgress, setRegistrationProgress] = useState(0); //Loading bar progress

  useEffect(() => {
    fetchData();
  }, []); // Make sure to include dependencies array to avoid infinite loop

  const fetchData = async () => {
    try {
      const responseData = await GetAll("academic", token);
      setClassList(responseData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [stringData, setStringData] = useState({
    userType: { value: "student", type: "text" },
    firstName: { value: "", type: "text" }, //Pass inital value and input type
    lastName: { value: "", type: "text" },
    email: { value: "", type: "email" },
    dateOfBirth: { value: "", type: "date" },
    studentMob: { value: "", type: "number" },
    fatherName: { value: "", type: "text" },
    motherName: { value: "", type: "text" },
    otherParents: { value: "", type: "text" },
    gender: { value: "", type: "select" },
    grade: { value: "", type: "select" },
    section: { value: "", type: "select" },
    bloodGroup: { value: "", type: "text" },
    address: { value: "", type: "textarea" },
  });

  const formatLabel = (key) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStringData((prevStringData) => ({
      ...prevStringData,
      [name]: { ...prevStringData[name], value }, // Update the 'value' property within the corresponding object
    }));
  };

  const handleClassChange = (event) => {
    event.preventDefault();

    const selectedClassIndex = event.target.value;
    setSelectedClass(selectedClassIndex);
    if (selectedClassIndex !== "Select class...") {
      const sections = classList[selectedClassIndex].sections.map(
        (section) => section.sectionName
      );
      setStringData((prevStringData) => ({
        ...prevStringData,
        grade: {
          value: classList[selectedClassIndex].className,
          type: prevStringData.grade.type,
        },
      }));
      setSectionList(sections);
    } else {
      setSectionList([]);
    }
  };

  // For loading bar
  const registerUserWithProgress = async (
    userType,
    postData,
    progressCallback
  ) => {
    return new Promise(async (resolve, reject) => {
      // Simulate slow API call with progress updates
      const totalTime = 3000; // Simulate slow API call for 3 seconds
      const interval = 100; // Update progress every 100ms
      let currentTime = 0;

      const progressInterval = setInterval(() => {
        currentTime += interval;
        const progress = Math.min((currentTime / totalTime) * 100, 100);
        progressCallback(progress);

        if (currentTime >= totalTime) {
          clearInterval(progressInterval);
          resolve("Student registered successfully");
        }
      }, interval);

      // Simulate registration API call
      try {
        const data = await RegisterUser(userType, postData);
        resolve(data);
      } catch (error) {
        clearInterval(progressInterval);
        reject(error);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = Object.values(stringData);

    const postData = values.reduce((acc, { value }, index) => {
      const key = Object.keys(stringData)[index];
      acc[key] = value.trim();
      return acc;
    }, {});
    e.preventDefault();

    const isEmpty = checkEmptyValues(postData);
    console.log(postData);
    if (isEmpty) {
      setIsRegistering(true);
      const data = await registerUserWithProgress(
        "student",
        postData,
        setRegistrationProgress
      );
      if (data === "User already registered.") {
        setMsg("Student already registerd");
        setMsgType("error");
        setIsRegistering(false);
      } else {
        setMsg("Student register successfully");
        setMsgType("success");
        setIsRegistering(false);
        setTimeout(() => {
          navigate("/users/student");
        }, 3000);
      }
    } else {
      console.log("Fileds are empty  ", isEmpty);
      setMsg("Fill the fields");
      setMsgType("warning");
    }
  };

  function checkEmptyValues(studentInfo) {
    for (const key in studentInfo) {
      if (!studentInfo[key]) {
        return false;
      }
    }
    return true;
  }

  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#F4F5F9", maxWidth: "1424px" }}
      >
        <DashBoardCard title="Add a student" visibility="hidden" />

        {isRegistering && (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: "999",
              backdropFilter: "blur(5px)", // Apply blur effect to background
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "40%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
              }}
            >
              <div style={{ textAlign: "center" }}>
                <progress
                  value={registrationProgress}
                  max="100"
                  style={{ width: "800px", height: "20px" }} // Set width and height for the progress bar
                />
                <p style={{ fontSize: "1.2rem" }}>
                  Registering student. Please wait...
                </p>
              </div>
            </div>
          </div>
        )}

        <section id="form" onSubmit={handleSubmit}>
          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-md-12 text-center">
                <h3
                  className="text-uppercase"
                  style={{ marginBottom: "1.5rem" }}
                >
                  Registration
                </h3>

                <div className="row">
                  <div className="col-md-12">
                    <form>
                      <div className="form-row">
                        {Object.entries(stringData)
                          .slice(1, 4)
                          .map(([menuItem, component]) => (
                            <InputComponent
                              label={formatLabel(menuItem)}
                              // key={menuItem}
                              lable={formatLabel(menuItem)}
                              type={component.type}
                              name={menuItem}
                              value={component.value}
                              onChange={(e) => handleInputChange(e)}
                              placeholder={`Enter ${formatLabel(
                                menuItem
                              )}`}
                            />
                          ))}

                        <div className="form-group col-md-2">
                          <h5 className="text-left">Blood Group</h5>
                          <input
                            type="text"
                            className="form-control"
                            id="inputDado"
                            placeholder="Blood Group"
                            name="bloodGroup"
                            required
                            value={stringData.bloodGroup.value}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>

                        <div className="form-group col-md-2">
                          <h5 className="text-left">DOB</h5>
                          <input
                            type="date"
                            className="form-control"
                            id="inputDado"
                            placeholder="Age"
                            required
                            name="dateOfBirth"
                            value={stringData.dateOfBirth.value}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>

                        <div className="form-group col-md-2">
                          <h5 className="text-left">Gender</h5>
                          <select
                            className="form-control"
                            name="gender"
                            id="inputDado"
                            onChange={(e) => handleInputChange(e)}
                            value={stringData.gender.value}
                            aria-label="Gender"
                          >
                            <option selected>Select</option>
                            <option value="2">Male</option>
                            <option value="3">Female</option>
                            <option value="3">Others</option>
                          </select>
                        </div>

                        {Object.entries(stringData)
                          .slice(5, 9)
                          .map(([menuItem, component]) => (
                            <InputComponent
                              label={formatLabel(menuItem)}
                              type={component.type}
                              name={menuItem}
                              onChange={(e) => handleInputChange(e)}
                              placeholder={`Enter ${formatLabel(
                                menuItem
                              )}`}
                            />
                          ))}
                        <div className="form-group col-md-4">
                          <h5 className="text-left">Grade</h5>
                          <select
                            className="form-control"
                            id="inputDado"
                            name="grade"
                            value={selectedClass}
                            onChange={handleClassChange}
                            required
                          >
                            <option selected>Select class...</option>
                            {classList.map((result, index) => (
                              <option value={index}>{result.className}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <h5 className="text-left">Section</h5>
                          <select
                            className="form-control"
                            id="inputDado"
                            name="section"
                            value={stringData.section.value}
                            disabled={!selectedClass}
                            onChange={(e) => handleInputChange(e)}
                            required
                          >
                            <option selected>Select section...</option>
                            {sectionList.map((result, index) => (
                              <option value={result}>{result}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label for="inputAddress">Address</label>
                        <input
                        required
                          type="textarea"
                          className="form-control"
                          id="inputDado"
                          name="address"
                          placeholder="Enter address"
                          value={stringData.address.value}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>

                      <button type="submit" className="btn btn-secondary btn-lg">
                        Register
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <NotifyMsg msg={notificationMsg} type={typeOfMsg}  />
      <ToastContainer />
    </>
  );
};

export default Admission;
