const URL = "https://13.53.163.70:9901/tva/user-authorization/api/v1/user/";

const RegisterUser = async (accountType, postData) => {
  try {
    const response = await fetch(`${URL}registerUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    if (response.ok) {
      const data = await response.json();
      const data_set = data.data;
      return data_set;
    } else {
      throw new Error("Register  failed");
    }
  } catch (error) {
    throw new Error("Register failed");
  }
};

const DeleteUser = async (accountType, userName, token) => {
  try {
    const response = await fetch(
      `${URL}deleteAccount?userType=${accountType}&username=${userName}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      const data_set = data.data;
      const status = response.status;
      return { status, data: data_set };
    }
  } catch (error) {
    throw new Error("Delete failed");
  }
};

const getInfoByUserName = async (accountType, userName, token) => {
  try {
    const response = await fetch(
      `${URL}getDetails?userType=${accountType}&username=${userName}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      const data_set = data.data;
      return data_set;
    }
  } catch (error) {
    throw new Error("Get request failed");
  }
};

const fetchListData = async (token, postData) => {
  try {
    const response = await fetch(`${URL}listUsers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(postData),
    });

    if (response.ok) {
      const data = await response.json();
      return data.data;
    }
  } catch (error) {
    throw new Error("Fetch userList failed", error);
  }
};

const updateUser = async (postData, token) => {
  try {
    const response = await fetch(`${URL}updateDetails`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(postData),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw new Error("Update request failed", error);
  }
};

export {
  RegisterUser,
  DeleteUser,
  getInfoByUserName,
  fetchListData,
  updateUser,
};
