import React from "react";
import Cards from "../../Cards/Card";
import DashBoardCard from "../../DashBoardCard/DashBoardCard";

const MainContent = () => {
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#F4F5F9", maxWidth: "1424px" }}
      >
        <DashBoardCard
          title="Dashboard"
          visibility="hidden"
          itemClick=""
        />

        

        <div className="row">
          <div className="col-xl-12" style={{paddingLeft:"30px", paddingBottom:"100px"}}>
            <div className="row">
              <div className="col-xl-8">
                <div className="row">
                  <div className="col-lg-6">
                    <Cards title={"student"} />
                  </div>
                  <div className="col-lg-6">
                    <Cards title={"teacher"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContent;
