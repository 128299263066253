import React from 'react';
import './style.css';
import kangarooImage from './kangaroo-bg.png'; // Adjust the path as needed

const ThankYouPage = () => {
  return (
    <div className="thank-you-container">
      <div className="thank-you-content">
        <h1>Thank you</h1>
        {/* <p className="breadcrumb">Home &gt; Thank you</p> */}
        <h2>Happy to hear from you!</h2>
        <p className="congratulations">
          Congratulations on making the First Five Years Count for your little one
        </p>
        <p className="toll-free">
          Toll free number
          <br />
          <span className="phone-number">&#128222; 1800-2106-868</span>
        </p>
      </div>
      <div className="thank-you-image">
        <img src={kangarooImage} alt="Kangaroo on a rocket pencil" />
      </div>
    </div>
  );
};

export default ThankYouPage;
